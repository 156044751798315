import React from "react"
import {connect} from "react-redux"
import "./Maintanance.css"
import logo from "../../media/biglogo.png"
import table from "../../media/table.png"
import banner from '../../media/maintan.png'

function Maintanance (props) {
    return (
        <>
            <img src={banner} alt="" className="banners" />
            <section id="maintanance">
                <img id="bigLogo" src={logo} alt="" srcSet="" />
                <p>Absolut Services is a Service Organisation with global local support offices. We provide prime services onsite to our Customers to enable a smooth running ICT operation. Absolut Services does provide today following services: telephone technical support, 7x24x365 onsite support services, parts, local warehousing and management, RMA management and logistics services.</p>
                            <h2>Headquarters</h2>
                            <p>ABSOLUT Services AG</p>
                            <p>In the Luberzen 19, 8902 Urdorf </p>
                            <p>Switzerland</p>
                            <p>support@absolut-services.ch</p>
                            <p><a href="tel:+41 41 552 55 26">+41 41 552 55 26</a></p> 
                            <h2>Onsite Support Offices: </h2>   
                            <table id="firstMaintTable">
                                <tbody>
                                    <tr>
                                        <td>Country</td>
                                        <td>City</td>
                                        <td>SLA onsite</td>
                                    </tr>
                                    <tr>
                                        <td>Austria</td>
                                        <td>
                                            <p>Reitschulgasse 4, 1010 Wien</p>
                                            <p>Europastraße 1, 5020 Salzburg</p> 
                                        </td>
                                        <td>
                                           <p>7x24x4h, NBD, NBDs </p>
                                           <p>7x24x4h, NBD, NBDs</p> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Germany</td>
                                        <td>
                                            <p>Kurfürstendamm 61, 10707 Berlin</p>
                                            <p>Heerbarg 31, 22391 Hamburg</p>
                                            <p>Main Taunus, 65843 Sulzbach (Frankfurt)</p>
                                            <p>Pasinger Bahnhofspl. 5, 81241 München</p>
                                        </td>
                                        <td>
                                            <p>7x24x4h, NBD, NBDs</p>
                                            <p>7x24x4h, NBD, NBDs</p>
                                            <p>7x24x4h, NBD, NBDs</p>
                                            <p>7x24x4h, NBD, NBDs</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Italy</td>
                                        <td>
                                            <p>Via Cordusio, 1, 20123 Milano</p>
                                            <p>Via del Ponte di Piscina Cupa 64, 00128 Roma</p>
                                        </td>
                                        <td>
                                            <p>7x24x4h, NBD, NBDs</p>
                                            <p>NBD, NBDs</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Lichtenstein</td>
                                        <td><p>Städtle 28, 9490 Vaduz</p> </td>
                                        <td><p>NBD, NBDs</p></td>
                                    </tr>
                                    <tr>
                                        <td>Switzerland</td>
                                        <td>
                                            <p>Luberzen 19, 8902 Urdorf (Zurich)</p> 
                                            <p>Avenue Ernest-Pictet 31, 1203 Genève</p> 
                                            <p>Via Lema 15, 6986 Novaggio (Ticino)</p> 
                                            <p>Parkstrasse 1b, 6214 Schenkon (Luzern)</p> 
                                        </td>
                                        <td>
                                           <p>7x24x4h, NBD, NBDs</p>
                                           <p>7x24x4h, NBD, NBDs</p>
                                           <p>7x24x4h, NBD, NBDs</p>
                                           <p>7x24x4h, NBD, NBDs</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Frankreich</td>
                                        <td>
                                           <p>5 Pl. Blanche, 75009 Paris</p>
                                           <p>2 Chem. de Gabardie, 31200 Toulouse</p>
                                        </td>
                                        <td>
                                            <p>7x24x4h, NBD, NBDs</p>
                                            <p>7x24x4h, NBD, NBDs</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Monaco</td>
                                        <td> <p>Ni Box, 35 Bd Louis II, 98000 Monaco</p> </td>
                                        <td> <p>7x24x4h, NBD, NBDs</p> </td>
                                    </tr>
                                    <tr>
                                        <td>Spanien</td>
                                        <td>
                                           <p>P.º de la Castellana, 75, 28003 Madrid</p>
                                           <p>Avinguda Diagonal, 635, 08028 Barcelona</p>
                                           <p>El Corte Ingles Marineda, Av. de Arteixo 43, 15008 A Coruña</p>
                                           <p>Cc Torre, C. Inca Garcilaso, 2, 41092 Sevilla</p>
                                        </td>
                                        <td>
                                           <p>7x24x4h, NBD, NBDs</p> 
                                           <p>7x24x4h, NBD, NBDs</p> 
                                           <p>7x24x4h, NBD, NBDs</p> 
                                           <p>7x24x4h, NBD, NBDs</p> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Hongkong</td>
                                        <td>
                                           <p>33 Canton Road, Tsim Sha Tsui, 1/F, HongKong</p> 
                                           <p>Other Countries in Europe are in Registration</p> 
                                        </td>
                                        <td>
                                            <p>NBDs</p>
                                            <p>-</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>      
                <h2>Open an Support ticket</h2>
                <div className="maintext">
                    Open an Absolute Prime Care support ticket
                    In the event of a fault, please contact ours in writing at support@absolut-services.ch or by telephone on +41 41 552 552 6
                    support hotline.

                    Please have one of the following information ready: serial number of the defective device or the absolute order number.
                </div>
                <h2>Depending on the SLA concluded, the following services apply:</h2>
                <img id="tableImg" src={table} alt="" srcSet="" />
                <h3>SLA Description</h3>
                <table id="firstMaintTable">
                    <tbody>
                        <tr>
                            <td>Prime-Care Standard 5x9xNBD</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Availability</td>
                            <td>daily from 00:00 to 24:00 (incl. public holidays)</td>
                        </tr>
                        <tr>
                            <td>on-call duty:</td>
                            <td>Repair period: weekdays (Mon to Fri) from 08:00 to 17:00</td>
                        </tr>
                        <tr>
                            <td>reaction time:</td>
                            <td>The reaction and error analysis takes place by telephone or e-mail within 4 hours - supported by the remote support tool. Replacement parts will be shipped within 1 business day of RMA registration. Please note the exact service features of your completed service.</td>
                        </tr>
                    </tbody>
                </table>
                <table id="firstMaintTable">
                    <tbody>
                        <tr>
                            <td>Prime-Care Standard 5x9xNBD</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Availability</td>
                            <td>daily from 00:00 to 24:00 (incl. public holidays)</td>
                        </tr>
                        <tr>
                            <td>on-call duty:</td>
                            <td>Repair period: weekdays (Mon to Fri) from 08:00 to 17:00</td>
                        </tr>
                        <tr>
                            <td>reaction time:</td>
                            <td>The reaction and error analysis takes place by telephone or e-mail within 4 hours - supported by the remote support tool.
                            The on-site assignment takes place no later than the next working day after the fault qualification has been completed. Please note the exact service features of your completed service.
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table id="firstMaintTable">
                    <tbody>
                            <tr>
                                <td>Prime-Care Standard 5x9xNBD</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Availability</td>
                                <td>daily from 00:00 to 24:00 (incl. public holidays)</td>
                            </tr>
                            <tr>
                                <td>on-call duty:</td>
                                <td>Repair period: weekdays (Mon to Fri) from 08:00 to 17:00</td>
                            </tr>
                            <tr>
                                <td>reaction time:</td>
                                <td>The reaction and error analysis takes place by telephone or e-mail within 4 hours - supported by the remote support tool.
                                The on-site deployment takes place within 4 hours after the fault qualification has been completed. Please note the exact service features of your completed service.
                                </td>
                            </tr>
                    </tbody>
                </table>
            </section>
        </>  
        
    )
}
export default connect()(Maintanance)