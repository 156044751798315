import React from "react"
import {connect} from "react-redux"

let outerFunc

function Technical (props) {
    // const items = [...document.querySelectorAll(".productItem")]
    // console.log(items)
    // items.map((item) => {
    //     item.addEventListener("click",(e) => {
    //         props.func()
    //         e.stopPropagation()
    //     } )
    //     return item
    // })
    outerFunc = props.func
    return (
        <>
            <div id="downWrapper" className="technicalWrap">
                <div className="headRow">
                    <h3>Maintanance Support</h3>
                    <span onClick={props.maint} className="productItem">Local Maintanance Service </span>
                </div>
                <div className="headRow">
                    <h3>Product Documentation</h3>
                    <span onClick={props.specs} className="productItem">Product Specification </span>
                </div>
            </div>
        </>
        
        
    )
}

const mapState = (state) => {
    return ({
        state:state
    })
}

const mapAct = (deploy) => {
    return (
        {
            maint: () => {
                outerFunc()
                deploy({type:"maint"})
            },
            specs: () => {
                outerFunc()
                deploy({type:"specs"})
            },
        }
    )
}
export default connect(mapState, mapAct)(Technical)