import React from "react"
import {connect} from "react-redux"

let outerFunc 

const Products = (props) => {
    // const items = [...document.querySelectorAll(".productItem")]
    // items.map((item) => {
    //     item.addEventListener("click", (e) => {
    //         props.func()
    //         e.stopPropagation()
    //     })
    //     return item
    // })
    outerFunc = props.func
    return (
        <>
            <div id="downWrapper">
                <div className="headRow">
                    <h3>Rack Servers</h3>
                    <span onClick={props.zero} className="productItem">Server v5 Generation</span>
                    <span onClick={props.first} className="productItem">1288H v6</span>
                    <span onClick={props.second} className="productItem">2288H v6</span>
                    <span onClick={props.third} className="productItem">2488H v6</span>
                    <span onClick={props.fourth} className="productItem">5288H v6</span>
                </div>
                <div className="headRow">
                    <h3>High-Density Server</h3>
                    <span onClick={props.fifth} className="productItem">X6000 v6</span>
                    <span onClick={props.sixth} className="productItem">XH321 v6</span>
                    <span onClick={props.seventh} className="productItem">XH321C v6</span>
                </div>
                <div className="headRow">
                    <h3>GPU Servers </h3>
                    <span className="productItem" onClick={props.eight}>G5500</span>
                </div>
                <div className="headRow">
                    <h3>Mission Critical Servers</h3>
                    <span onClick={props.ninth} className="productItem">KunLun 9008</span>
                </div>
                <div className="headRow">
                    <h3>Server Managment Software</h3>
                    <span onClick={props.eleventh} className="productItem">iBMC</span>
                    <span onClick={props.twelvth} className="productItem">FusionServer Tools</span>
                    <span onClick={props.thirteenth} className="productItem">Management Ecosystems</span>
                    <span onClick={props.tenth} className="productItem">Fusion Director</span>
                </div>
            </div>
        </>
        
        
    )
}

const mapState = (state) => {
    return ({
        state:state
    })
}

const mapAct = (deploy) => {
    return (
        {
            zero: () => {
                outerFunc()
                deploy({type:"zero"})
            },
            first: () => {
                outerFunc()
                deploy({type:"fst"})
            },
            second: () => {
                outerFunc()
                deploy({type:"sec"})
            },
            third: () => {
                outerFunc()
                deploy({type:"third"})
            },
            fourth: () => {
                outerFunc()
                deploy({type:"fourth"})
            },
            fifth: () => {
                outerFunc()
                deploy({type:"fifth"})
            },
            sixth: () => {
                outerFunc()
                deploy({type:"sixth"})
            },
            seventh: () => {
                outerFunc()
                deploy({type:"seventh"})
            },
            eight: () => {
                outerFunc()
                deploy({type:"eight"})
            },
            ninth: () => {
                outerFunc()
                deploy({type:"ninth"})
            },
            tenth: () => {
                outerFunc()
                deploy({type:"tenth"})
            },
            eleventh: () => {
                outerFunc()
                deploy({type:"eleventh"})
            },
            twelvth: () => {
                outerFunc()
                deploy({type:"twelvth"})
            },
            thirteenth: () => {
                outerFunc()
                deploy({type:"thirteenth"})
            },
        }
    )
}

export default connect(mapState, mapAct)(Products)