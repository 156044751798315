import React, {useState} from "react"
import emailjs from "emailjs-com"
import ReCAPTCHA from "react-google-recaptcha";
import banner from "../media/contact.png"
import "./Contact.css"

export default function Contact () {
    const [captcha,setCaptcha] = useState(false)
    function captchaDone () {
        const but = document.getElementById("send")
        const checkbox = document.getElementById("terms")
        setCaptcha(true)
        if(checkbox.checked) {
            but.className += " activeSend"
        }
    }
    const submit = (e) => {
        const checkbox = document.getElementById("terms")
        e.preventDefault();
        if(checkbox.checked) {
            const inputs = [...document.querySelectorAll("input")]
            let normal = true
            inputs.forEach((el) => {
                if(el.value === "") {
                    normal = false
                    el.className += " error"
                }
                else {
                    if(el.id !== "terms") {el.className = "contactInput"}
                }
                document.getElementById("send").className = "contactInput"
            })
            if(!normal) {
                document.getElementById("terms").checked = false
                return
            }
            // emailjs.sendForm('service_85keurj', 'template_e086a3s', e.target, 'user_I9RkM2wjvwskcN9ZDoUS0')
            // .then((result) => {
            //     document.getElementById("send").value = "Sent!"
            //     e.target.reset()
            //     document.getElementById("terms").checked = false
            // }, (error) => {
            //     console.log(error.text);
            // });
            const gender = document.getElementsByName("gender")[0].value
            const name = document.getElementsByName("name")[0].value
            const surname = document.getElementsByName("surname")[0].value
            const func = document.getElementsByName("function")[0].value
            const company = document.getElementsByName("company")[0].value
            const phone = document.getElementsByName("phone")[0].value
            const location = document.getElementsByName("location")[0].value
            const postal = document.getElementsByName("postal")[0].value
            const but = document.getElementById("send")
            but.value = "Loading..."
            window.Email.send({
                SecureToken : "3b00537d-65cc-40a2-a91e-eb8b46f5ee8e ",
                To : 'info@xfusion.shop',
                From : "dev.vladbrown@gmail.com",
                Subject : `New contact request `,
                Body : `Gender: ${gender}

                        Name: ${name}
                        
                        Surname: ${surname}
                        
                        Function: ${func}
                        
                        Company: ${company}
                        
                        Phone: ${phone}
                        
                        Location: ${location}
                        
                        Postal: ${postal}`
            }).then((message) => {
                e.target.reset()
                but.value = "Message Sent!"
                but.className += " succesfulBut"
             });
        }
        
    };
    function checkbox (e) {
        const but = document.getElementById("send")
        if(e.target.checked && captcha) {but.className += " activeSend"}
        debugger
        if(!e.target.checked) {but.className = "contactInput"}
    }
    return (
        <>
            <section id="contact">
                <img src={banner} alt="" className="banners" />
                <h1>Request for Quote</h1>
                <div id="contactText">
                    <div id="contactColWrap">
                        <div className="contactColumn">
                            <h3>Headquarters</h3>
                            <p>ABSOLUT Distribution AG</p>
                            <p>Parkstrasse 1b</p>
                            <p>6214 Schenkon / Lucerne</p>
                            <p>Switzerland</p>
                        </div>
                         <div className="contactColumn">
                            <h3>European Offices:</h3>
                        </div>
                        <table>
                            <tbody id="contactTable">
                                <tr>
                                    <td>Country</td>
                                    <td>City</td>
                                </tr>
                                <tr>
                                    <td>Austria</td>
                                    <td>Reitschulgasse 4, 1010 Wien</td>
                                </tr>
                                <tr>
                                    <td>Germany</td>
                                    <td>Pasinger Bahnhofspl. 5, 81241 München</td>
                                </tr>
                                <tr>
                                    <td>Italy</td>
                                    <td>Via Cordusio, 1, 20123 Milano  </td>
                                </tr>
                                <tr>
                                    <td>Lichtenstein</td>
                                    <td>Städtle 28, 9490 Vaduz </td>
                                </tr>
                                <tr>
                                    <td>Switzerland</td>
                                    <td>Luberzen 19, 8902 Urdorf (Zurich)</td>
                                </tr>
                                <tr>
                                    <td>Frankreich</td>
                                    <td>5 Pl. Blanche, 75009 Paris</td>
                                </tr>
                                <tr>
                                    <td>Monaco</td>
                                    <td>Ni Box, 35 Bd Louis II, 98000 Monaco</td>
                                </tr>
                                <tr>
                                    <td>Spanien</td>
                                    <td>P.º de la Castellana, 75, 28003 Madrid</td>
                                </tr>
                            </tbody>
                        </table>
                        {/*<div className="contactColumn">
                            <h3>Training and Logistics Center Zurich</h3>
                            <p>In the Luberzen 19</p>
                            <p>8902 Urdorf / Zurich</p>
                        </div>
                        <div className="contactColumn">
                            <h3>Sales Office Ticino</h3>
                            <p>Via Lema 15</p>
                            <p>6986 Novaggio</p>
                        </div>
                        <div className="contactColumn contactColumnLast">
                            <a className="tel" href="tel:+41 43 534 72 00">+41 43 534 72 00</a>
                            <a className="mail"href = "mailto: info@absolut-distribution.ch">info@absolut-distribution.ch</a>
                        </div> */}
                    </div>
                </div>
                <form onSubmit={submit} id="partnerForm" className="contactForm" action="">
                    <div className="formColumn contactFormCol">
                        <select className="contactInput" name="gender">
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                        <input className="contactInput" name="name" placeholder="Name" type="text" />
                        <input className="contactInput" name="surname" placeholder="Surname" type="text" />
                    </div>
                    <div className="formColumn contactFormCol">
                        <input className="contactInput" name="function" placeholder="Function" type="text" />
                        <input className="contactInput" name="company" placeholder="Company" type="text" />
                        <input className="contactInput" name="phone" placeholder="Mobile phone" type="text" />
                    </div>
                    <div className="formColumn contactFormCol">
                        <input className="contactInput" name="location" placeholder="Location" type="text" />
                        <input className="contactInput" name="postal" placeholder="Postal Code" type="text" />
                        <input className="contactInput" type="submit" id="send" value="Send" />
                    </div>
                </form>
                <div id="termsWrapper">
                    <div>
                        <input onChange={checkbox} type="checkbox" id="terms" name="scales"/>
                        <label for="terms">I agree that my details from the contact form will be collected and processed to answer my request in accordance with the data protection declaration.</label>
                    </div>
                </div>
                <ReCAPTCHA
                    sitekey="6LdPbH8fAAAAAF7ITOGQ30RMJWrYL50UR06fE9-F"
                    onChange={captchaDone}
                />,
            </section>
        </>
 
    )
}