import React from "react"
import {connect} from "react-redux"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';


import "./MainSection.css";
import 'swiper/css';
import "swiper/css/autoplay"
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function MainSection (props) {
    return (
        <main>
            <div id="mainWrapper">
                <div id="banner" onClick={props.about}></div>
            </div>
            <section id="carousel">
                <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    autoplay
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination
                    navigation
                >
                    <SwiperSlide className="slide">
                        <div onClick={props.maint} className="smallBanner1 smallBanner" alt=""></div>
                    </SwiperSlide>
                    <SwiperSlide className="slide">
                        <div onClick={props.sec} className="smallBanner2 smallBanner" alt=""></div>
                    </SwiperSlide>
                    <SwiperSlide className="slide">
                        <div onClick={props.about} className="smallBanner3 smallBanner" alt=""></div>
                    </SwiperSlide>
                    <SwiperSlide className="slide">
                        <div onClick={props.specs} className="smallBanner4 smallBanner" alt=""></div>
                    </SwiperSlide>
                </Swiper>
            </section>
            <section id="story">
                <h1>Success Stories</h1>
                <div id="storyBlocks">
                    <div id="butBlock">
                        <div onClick={props.own1} className="successBut">Virtualisation Stories</div>
                        <div onClick={props.own2} className="successBut">Hosting Stories</div>
                        <div onClick={props.hpc} className="successBut">HPC Stories</div>
                    </div>
                    <div id="storyImgs">
                        <div onClick={props.hpc} id="fstoryImg">
                            <h2>FusionServer Succeeds in Upgrading HPC System for EPFL</h2>
                        </div>
                        <div onClick={props.own1} id="secstoryImg">
                            <h2>Our Virtualisation stories</h2>
                        </div>
                    </div>
                </div>
            </section>
            <button onClick={props.about} id="learnMoreMain">
                Learn More
            </button>
        </main>
    )
}
const mapState = (state) => {
    return ({
        state:state
    })
}

const mapAct = (deploy) => {
    return (
        {
            hpc: () => deploy({type:"hpc"}),
            own1: () => deploy({type:"own1"}),
            own2: () => deploy({type:"own2"}),
            about: () => deploy({type:"about"}),
            sec: () => deploy({type:"sec"}),
            maint: () => deploy({type:"maint"}),
            specs: () => deploy({type:"specs"}),
        }
    )
}

export default connect(mapState, mapAct)(MainSection)