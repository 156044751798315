import first from "../media/bigPic/1288.png" 
import second from "../media/bigPic/2288.png" 
import third from "../media/bigPic/2488H.png" 
import fourth from "../media/bigPic/5288.png" 
import fifth from "../media/bigPic/x6000.png" 
import sixth from "../media/bigPic/XH321.png" 
import seventh from "../media/bigPic/XH321C.png" 
import zero from "../media/bigPic/zero.png" 
import eight from "../media/bigPic/G5500.png" 
import ninth from "../media/bigPic/KunLun.png" 
import tenth from "../media/smallSoft1.jpeg"
import eleventh from "../media/smallSoft2.jpeg"
import twelvth from "../media/smallSoft3.png"
import thirteenth from "../media/smallSoft4.jpg"

const info = {
    "zero"  : {
        "img":zero,
        "head":"FusionServer 2288H V5 Rack Server",
        "descr":"(FusionServer 2288H V5 is the former Server Technology, where we can support you still with expanding parts to upgread your existing FusionServer. For any custome request please contact us.) The FusionServer 2288H V5 is an a 2U, 2-socket rack server. FusionServer 2288H V5 has been verified by Intel as an Intel® Select Solution for NFVI and it supports the configuration of over 100 types of resources with just one model, flexibly meeting the hardware resource requirements of diverse workloads. It is an ideal choice for diverse applications, such as cloud computing, virtualization, databases, HPC, and big data processing.",
        "feature1":"Optimally Balanced Configuration with Ultra-Large Storage",
        "feature2":"Smart Power Savings and Better Energy Efficiency",
        "feature3":"Unmatched Intelligent Manageability and Openne",
        "model":"FusionServer 2288H V5",
        "formfactor":"2U, 2-socket rack server",
        "processors":"1 or 2 1st Generation Intel® Xeon® Scalable processors (3100/4100/5100/6100/8100 series), up to 205W 1 or 2 2nd Generation Intel® Xeon® Scalable processors (3200/4200/5200/6200/8200 series), up to 205W",
        "chipset":"Lewisburg-2",
        "memory":"24 DDR4 DIMM slots, 2933 MT/s; up to 12 Intel® Optane™ PMem modules (100 series), 2666 MT/s",
        "localstorage":"Supports hot-plug hard drives and the following configuration options:10 x 2.5-inch hard drives (6–8 NVMe SSDs and 2–4 SAS/SATA drives, with a total number of 10 or less) 10 x 2.5-inch SAS/SATA/SSDs (2–4 NVMe SSDs and 6–8 SAS/SATA HDDs, with a total number of 10 or less) 10 x 2.5-inch SAS/SATA/SSDs 8 x 2.5-inch SAS/SATA hard drives 4 x 3.5-inch SAS/SATA hard drives Flash storage: 2 M.2 SSDs",
        "raidsupport":"RAID 0, 1, 10, 5, 50, 6, or 60 Configured with a supercapacitor for power-off protection for the cache (optional) RAID level migration, drive roaming, self-diagnosis, and web-based remote configuration",
        "network":"LOM: 2 x 10GE + 2 x GE ports Flexible NIC: 2 x GE, 4 x GE, 2 x 10GE, 2 x 25GE, or 1/2 x 56G FDR IB ports",
        "pcieexpansion":"Up to 5 PCIe 3.0 slots, including 1 for a RAID controller card and 1 for a flexible NIC",
        "fanmodules":"7 hot-swappable fan modules with optional N+1 redundancy",
        "powersupply":"2 hot-swappable PSUs with support for 1+1 redundancy and the following configuration options: 550W AC Platinum PSUs 900W AC Platinum/Titanium PSUs 1,500W AC Platinum PSUs 1,500W 380V HVDC PSUs 1,200W -48V to -60V DC PSUs",
        "management":"iBMC integrates one dedicated management GE network port to provide comprehensive management features such as fault diagnosis, automated O&M, and hardware security hardening. iBMC supports standard interfaces such as Redfish, SNMP, and IPMI 2.0; provides a remote management interface based on HTML5/VNC KVM; supports CD-free deployment and the Agentless feature, simplifying management. (Optional) Configured with the FusionDirector management software to provide advanced management features such as stateless computing, batch OS deployment, and automated firmware upgrade, enabling smart and automatic entire-lifecycle management.",
        "operatingsystems":"Microsoft Windows Server, SUSE Linux Enterprise Server, VMware ESXi, Red Hat Enterprise Linux, CentOS, Oracle, Ubuntu, Debian, openEuler, etc.",
        "securityfeatures":"Supports power-on password, administrator password, Trusted Platform Module (TPM) 2.0, security panel, secure boot, and cover opening detection.",
        "operatingtemperature":"5ºC to 45ºC (41ºF to 113ºF), and ASHRAE A3 and A4 compliant",
        "certifications":"CE, UL, FCC, CCC, and RoHS",
        "installationkit":"L-shaped guide rails, adjustable guide rails, and holding rails",
        "dimensions":"Chassis with 3.5-inch hard drives: 43 mm x 447 mm x 748 mm (3.39 in. x 17.60 in. x 29.45 in.) Chassis with 2.5-inch hard drives: 43 mm x 447 mm x 708 mm (3.39 in. x 17.60 in. x 27.87 in.)",
        "3d":"https://support.xfusion.com/server-3d/res/server/2288hv5/index.html?lang=en"
    },
    "first"  : {
        "img":first,
        "head":"FusionServer 1288H V6 Rack Server",
        "descr":"FusionServer 1288H V6 Rack Server The FusionServer 1288H V6 is a 1U 2-socket rack server. It improves space utilization for data centers and is ideal for high-density deployment scenarios for workloads such as cloud computing, virtualization, high-performance computing (HPC), and big data processing. The 1288H V6 is configured with two Intel® Xeon® Scalable processors and provides up to 32 DDR4 DIMMs, and 4 x 3.5-inch or 10 x 2.5-inch local storage resources (configurable with 4 or 10 NVMe SSDs). It incorporates patented technologies, such as Dynamic Energy Management Technology (DEMT) and Fault Diagnosis & Management (FDM), and integrates FusionDirector software for entire-lifecycle management, helping customers drive down operating expense (OPEX) and improve return on investment (ROI).",
        "feature1":"High Density, Ultimate Computing Power",
        "feature2":"Flexible Expansion for Diversified Applications",
        "feature3":"Innovation and Intelligent O&M",
        "model":"FusionServer 1288H V6",
        "formfactor":"1U rack server",
        "processors":"One or two 3rd Gen Intel® Xeon® Scalable Ice Lake processors (8300/6300/5300/4300 series) thermal design power (TDP) up to 270 W",
        "chipset":"Intel C621A",
        "memory":"32 DDR4 DIMMs, up to 3,200 MT/s; 16 Optane™ PMem 200 series, up to 3,200 MT/s.",
        "localstorage":"Supports various drive configurations and hot swappable: • 10 x 2.5-inch drives (6–10 NVMe SSDs and 0–4 SAS/SATA drives, with a total number of 10 or less) • 10 x 2.5-inch drives (2–4 NVMe SSDs and 6–8 SAS/SATA drives, with a total number of 10 or less) • 10 x 2.5-inch SAS/SATA/SSD drives • 8 x 2.5-inch SAS/SATA/SSD drives • 4 x 3.5-inch SAS/SATA/SSD drivesSupports flash storage:• Dual M.2 SSDs",
        "raidsupport":"Supports RAID 0, 1, 10, 5, 50, 6, or 60, optional supercapacitor for cache data power failure protection, RAID level migration, drive roaming, self-diagnosis, and remote web-based configuration.",
        "network":"Provides expansion capability of multiple types of networks. Provides the OCP 3.0 network adapter. The two FlexIO card slots support two OCP 3.0 network adapter respectively, which can be configured as required. Hot swappable function supported.",
        "pcieexpansion":"Provides six PCIe slots, including one PCIe slot dedicated for RAID card, two FlexIO card slots dedicated for OCP 3.0 network adapters, and three PCIe 4.0 slots for standard PCIe cards.",
        "fanmodules":"Seven hot-swappable counter-rotating fan modules in N+1 redundancy mode",
        "powersupply":"Two hot-swappable PSUs in 1+1 redundancy mode. Supported options include: • 900 W AC Platinum/Titanium PSUs (input: 100 V to 240 V AC, or 192 V to 288 V DC) • 1500 W AC Platinum PSUs 1000 W (input: 100 V to 127 V AC) 1500 W (input: 200 V to 240 V AC, or 192 V to 288 V DC) • 1500 W 380 V HVDC PSUs (input: 260 V to 400 V DC) • 1200 W -48 V to -60 V DC PSUs (input: -38.4 V to -72 V DC) • 2000 W AC Platinum PSUs 1800 W (input: 200 V to 220 V AC, or 192 V to 200 V DC) 2000 W (input: 220 V to 240 V AC, or 200 V to 288 V DC)",
        "management":"The iBMC chip integrates one dedicated Gigabit Ethernet (GE) management port to provide comprehensive management functions such as fault diagnosis, automated O&M, and hardware security hardening. • The iBMC supports standard interfaces such as Redfish, SNMP, and IPMI 2.0; provides a remote management user interface based on HTML5/VNC KVM; supports CD-free deployment and Agentless for smart and simplified management. • (Optional) Configured with the FusionDirector management software to provide advanced management functions such as stateless computing, batch OS deployment, and automated firmware upgrade, enabling automatic management throughout the lifecycle.",
        "operatingsystems":"Microsoft Windows Server, SUSE Linux Enterprise Server, VMware ESXi, Red Hat Enterprise Linux, CentOS, Oracle, Ubuntu, Debian, openEuler, etc.",
        "securityfeatures":"Supports power-on password, administrator password, Trusted Platform Module (TPM) 2.0, security panel, secure boot, and cover opening detection.",
        "operatingtemperature":"5°C to 45°C (41°F to 113°F) (ASHRAE Classes A1 to A4 compliant)",
        "certifications":"CE, UL, FCC, CCC, VCCI, RoHS, etc",
        "installationkit":"Supports L-shaped guide rails, adjustable guide rails, and holding rails.",
        "dimensions":"Chassis with 3.5-inch drives: 43.5 mm x 447 mm x 790 mm, Chassis with 2.5-inch drives: 43.5 mm x 447 mm x 790 mm",
        "3d":"https://support.xfusion.com/server-3d/res/server/1288hv6/index.html?lang=en"
    },
    "second"  : {
        "img":second,
        "head":"FusionServer 2288H V6 Rack Server",
        "descr":"The FusionServer 2288H V6 is a 2U 2-socket rack server with flexible configurations and can be widely used in cloud computing, virtualization, databases, and big data. The 2288H V6 is configured with two Intel® Xeon® Scalable processors, 16/32 DDR4 DIMMs, and 14 PCIe slots, providing large-capacity local storage resources. It incorporates patented technologies, such as DEMT and FDM, and integrates FusionDirector software for entire-lifecycle management, helping customers drive down OPEX and improve ROI.",
        "feature1":"Larger Storage",
        "feature2":"Robust Computing Power",
        "feature3":"More Configurations",
        "model":"FusionServer 2288H V6",
        "formfactor":"2U rack server",
        "processors":"One or two 3rd Gen Intel® Xeon® Scalable Ice Lake processors (8300/6300/5300/4300 series), TDP up to 270 W",
        "chipset":"Intel C621A",
        "memory":"16/32 DDR4 DIMMs, up to 3,200 MT/s; 16 Optane™ PMem 200 series, up to 3,200 MT/s.",
        "localstorage":"Supports various drive configurations and hot swappable: • 8-31 x 2.5-inch SAS/SATA/SSD drives • 12-20 x 3.5-inch SAS/SATA drives • 4/8/16/24 NVMe SSDs • Supports a maximum of 45 x 2.5-inch drives or 34 full-NVMe SSDs Supports flash storage: • Dual M.2 SSDs",
        "raidsupport":"Supports RAID 0, 1, 10, 1E, 5, 50, 6, or 60, optional supercapacitor for cache data power failure protection, RAID level migration, drive roaming, self-diagnosis, and remote web-based configuration.",
        "network":"Provides expansion capability of multiple types of networks.Provides the OCP 3.0 network adapter. The two FlexIO card slots support two OCP 3.0 network adapter respectively, which can be configured as required. Hots wappable function supported.",
        "pcieexpansion":"Provides a maximum of fourteen PCIe 4.0 slots, including one PCIe slot dedicated for RAID card, two FlexIO card slots dedicated for OPC 3.0, and eleven PCIe 4.0 slots for standard PCIe cards.",
        "fanmodules":"Four hot-swappable counter-rotating fan modules in N+1 redundancy mode",
        "powersupply":"• 900 W AC Platinum/Titanium PSUs (input: 100 V to 240 V AC, or 192 V to 288 V DC) • 1500 W AC Platinum PSUs 1000 W (input: 100 V to 127 V AC) 1500 W (input: 200 V to 240 V AC, or 192 V to 288 V DC) • 1500 W 380 V HVDC PSUs (input: 260 V to 400 V DC) • 1200 W 1200 W -48 V to -60 V DC PSUs (input: -38.4 V to -72 V DC) • 3000 W AC Titanium PSUs 2500 W (input: 200 V to 220 V AC) 2900 W (input: 220 V to 230 V AC) 3000 W (input: 230 V to 240 V AC) • 2000 W AC Platinum PSUs 1800 W (input: 200 V to 220 V AC, or 192 V to 200 V DC) 2000 W (input: 220 V to 240 V AC, or 200 V to 288 V DC)",
        "operatingsystems":"Microsoft Windows Server, SUSE Linux Enterprise Server, VMware ESXi, Red Hat Enterprise Linux, CentOS, Oracle, Ubuntu, Debian, openEuler, etc.",
        "securityfeatures":"Supports power-on password, administrator password, TPM 2.0, security panel, secure boot, and cover opening detection.",
        "operatingtemperature":"5°C to 45°C (41°F to 113°F) (ASHRAE Classes A1 to A4 compliant)",
        "certifications":"CE, UL, CCC, FCC, VCCI, RoHS, etc",
        "installationkit":"Supports L-shaped guide rails, adjustable guide rails, and holding rails.",
        "dimensions":"Chassis with 3.5-inch drives: 86.1 mm x 447 mm x 790 mmChassis with 2.5-inch drives: 86.1 mm x 447 mm x 790 mm",
        "3d":"https://support.xfusion.com/server-3d/res/server/2288hv6/index.html?lang=en"
    },
    "third"  : {
        "img":third,
        "head":"FusionServer 2488H V6 Rack Server",
        "descr":"FusionServer 2488H V6 is the lastest 2U, 4-socket (4S) rack server, ideal for computing-intensive scenarios such as cloud computing, virtualization, high-performance computing (HPC), database, and SAP HANA. In virtualization scenarios, the OPEX of one FusionServer 2488H V6 is lower than that of two 2U, 2S rack servers. The 2488H V6 houses four 3rd Gen Intel® Xeon® Scalable processors in a 2U space, and supports 48 DDR4 DIMMs, 11 PCIe slots, and up to 25 x 2.5'' drives for local storage. It incorporates patented technologies such as Dynamic Energy Management Technology (DEMT) and Fault Diagnosis & Management (FDM), and can be configured with FusionDirector software for entire-lifecycle management, helping drive down OPEX and improve ROI.",
        "feature1":"Superior Performance with Higher Efficiency",
        "feature2":"Smart Power Savings and Better Energy Efficiency",
        "feature3":"Unmatched Intelligent Manageability and Openness",
        "model":"FusionServer 2288H V6",
        "formfactor":"2U rack server",
        "processors":"One or two 3rd Gen Intel® Xeon® Scalable Ice Lake processors (8300/6300/5300/4300 series), TDP up to 270 W",
        "chipset":"Intel C621A",
        "memory":"16/32 DDR4 DIMMs, up to 3,200 MT/s; 16 Optane™ PMem 200 series, up to 3,200 MT/s.",
        "localstorage":"Supports various drive configurations and hot swappable: • 8-31 x 2.5-inch SAS/SATA/SSD drives • 12-20 x 3.5-inch SAS/SATA drives • 4/8/16/24 NVMe SSDs • Supports a maximum of 45 x 2.5-inch drives or 34 full-NVMe SSDs Supports flash storage: • Dual M.2 SSDs",
        "raidsupport":"Supports RAID 0, 1, 10, 1E, 5, 50, 6, or 60, optional supercapacitor for cache data power failure protection, RAID level migration, drive roaming, self-diagnosis, and remote web-based configuration.",
        "network":"Provides expansion capability of multiple types of networks.Provides the OCP 3.0 network adapter. The two FlexIO card slots support two OCP 3.0 network adapter respectively, which can be configured as required. Hots wappable function supported.",
        "pcieexpansion":"Up to 11 PCIe 3.0 slots, including one dedicated PCIe slot for the OCP 3.0 NIC",
        "fanmodules":"6 hot-swappable fan modules in N+1 redundancy mode",
        "powersupply":"2 hot-swappable PSUs with support for 1+1 redundancy and the following configuration options: • 3000 W AC Titanium PSUs 2500 W (input: 200 V to 220 V AC) 2900 W (input: 220 V to 230 V AC) 3000 W (input: 230 V to 240 V AC) • 2000 W AC Platinum PSUs 1800 W (input: 200 V to 220 V AC, or 192 V to 200 V DC) 2000 W (input: 220 V to 240 V AC, or 200 V to 288 V DC) • 900 W AC Platinum/Titanium PSUs (input: 100 V to 240 V AC, or 192 V to 288 V DC) • 1500 W HVDC PSUs (input: 260 V to 400 V AC) • 1200 W DC PSUs (input: -38.4 V to -72 V DC)",
        "operatingsystems":"Microsoft Windows Server, Red Hat Enterprise Linux, SUSE Linux Enterprise Server, CentOS, Citrix XenServer, and VMware ESXi.",
        "securityfeatures":"Power-on password, administrator password, TCM/TPM 2.0, security front panel, and out-of-box intrusion alarm, etc.",
        "operatingtemperature":"5°C to 45°C (41°F to 113°F), compliant with ASHRAE Classes A3 and A4",
        "certifications":"CE, UL, FCC, CCC, and RoHS, etc.",
        "installationkit":"Adjustable guide rails, holding rails, and cable management arms (CMAs)",
        "dimensions":"Chassis: 86.1 mm x 447 mm x 790 mm (3.39 in. x 17.60 in. x 31.10 in.)",
        "3d":"https://support.xfusion.com/server-3d/res/server/2488hv6/index.html?lang=en"
    },
    "fourth"  : {
        "img":fourth,
        "head":"FusionServer 5288 V6 Rack Server",
        "descr":"FusionServer 5288 V6 is a 4U 2-socket rack server that meets service requirements such as hot, warm, and cold data tiered deployment and historical data archiving. With its efficient design, FusionServer 5288 V6 ensures excellent computing performance and provides flexible and ultra-large local storage expansion capabilities, reducing data storage costs. The 5288 V6 can be configured with two Intel Xeon scalable processor, 32 DDR4 DIMMs, and 44 3.5-inch + 4 NVMe SSDs in a 4 U space.Integrates patented technologies such as DEMT intelligent power management and FDM intelligent fault management, and can be configured with FusionDirector full-lifecycle management software, effectively reducing OPEX and improving ROI.",
        "feature1":"Ultra-large local storage",
        "feature2":"Super video analytics storage",
        "feature3":"Diversified and flexible expansion",
        "model":"FusionServer 5288 V6",
        "formfactor":"4U Rack Server",
        "processors":"1/2 3rd Generation Intel® Xeon® scalable processor (Ice Lake) (8300 / 6300 / 5300 / 4300 Series) , up to 270W",
        "chipset":"Intel® C621A",
        "memory":"32 DDR4 memory slots, up to 3200 MT/s; Up to 16 Intel® Optane TM Persistent Memory 200 Series, up to 3200MT/s",
        "localstorage":"Various hard disk configurations are supported. The hard disks are hot swappable. · 36 to 44 3.5-inch SAS/SATA HDDs and 4 NVMe SSDs · 24 x 3.5-inch SAS/SATA front hard disks · 4 3.5-inch SAS/SATA internal hard disks· 16 3.5-inch SAS/SATA hard disks and four 2.5-inch SAS/SATA/NVMe rear hard disks· 12 3.5-inch SAS/SATA disks and eight 2.5-inch rear hard disks (4 x 2.5 HDDs + 4 x 2.5 SAS/SATA/NVMe)Flash storage: · Dual M.2 SSDs",
        "raidsupport":"Supports RAID 0, 1, 5, 50, 6, and 60, cache supercapacitor protection, RAID level migration, disk roaming, self-diagnosis, and web-based remote configuration, and multiple network expansion capabilities.",
        "network":"OCP 3.0 NICThe two FLEX I/O card slots support two OCP 3.0 NICs, which can be configured as required. Supports multiple OCP 3.0 NICs.· Supports hot swap.",
        "pcieexpansion":"A maximum of 11 PCIe 4.0 slots, including one dedicated PCIe slot for the RAID controller card, two dedicated FLEX I/O slots for OPC3.0, and eight standard PCIe 4.0 slots",
        "fanmodules":"Four hot-swappable fans, supporting N+1 redundancy",
        "powersupply":"2 redundant hot-swappable PSUs are configured in 1+1 redundancy mode. The optional specifications are as follows: · 900W AC Platinum/Titanium power supply (Input: 100–240 V AC or 192–288 V DC) · 1500 W AC Platinum PSU · 1000 W (input: 100 V AC to 127 V AC) · 1500 W (input: 200–240 V AC or 192–288 V DC) · 2000 W AC Platinum PSU · 1800 W (input: 200–220 V AC or 192–200 V DC) · 2000 W (input: 220–240 V AC or 200–288 V DC)",
        "operatingsystems":"Microsoft Windows Server、SUSE Linux Enterprise Server、VMware ESXi、Red Hat Enterprise Linux、CentOS、Oracle Ubuntu、Debian、openEuler etc.",
        "securityfeatures":"Security features such as power-on password, administrator password, TPM 2.0, security panel, secure boot, and cover opening detection",
        "operatingtemperature":"Operating temperature: 5°C~40°C (41ºF~104ºF) (ASHARE Class A1/A2/A3)",
        "certifications":"CE、UL、CCC、FCC、VCCI、RoHS etc.",
        "installationkit":"L-shaped guide rails, retractable guide rails, and holding rails",
        "dimensions":"Chassis dimensions: 175 mm x 447 mm x 790 mm",
        "3d":"https://support.xfusion.com/server-3d/res/server/5288v6/index.html?lang=en"
    },
    "fifth" : {
        "img":fifth,
        "head":"FusionServer X6000 V6 High-Density Server",
        "descr":"The FusionServer X6000 V6 server is a new-generation 2 U high-density server designed to meet the requirements of Internet, HPC, cloud computing, and data center applications. It optimizes software-defined storage, big data, and SDI infrastructure and innovates architectures to meet large-scale server deployment requirements.",
        "feature1":"High-density Computing and Ultimate Performance",
        "feature2":"Aggregation Management and easy Maintenance",
        "feature3":"Shared Architecture and Energy Saving",
        "model":"FusionServer X6000 V6",
        "formfactor":"2 U 4-node subrack",
        "nodesystem":"4 1U half-width 2-socket servers",
        "vcc":"4 1200 W, 1500 W, 2000 W, or 3000 W AC PSUs in 1+1 or 2+2 redundancy and hot swap",
        "powersupply":"100–240 V AC, 48 V DC (1200 W), and 336 V DC (1500 W)",
        "fan":"4 8080+ fan modules in N+1 redundancy and hot swap",
        "operatingtemperature":"5ºC - 35ºC",
        "certifications":"CE、UL、FCC、CCC、VCCI、RoHS etc.",
        "dimensions":"86.1mm x 447mm x 899mm",
        "pcieexpansion":"A maximum of 11 PCIe 4.0 slots, including one dedicated PCIe slot for the RAID controller card, two dedicated FLEX I/O slots for OPC3.0, and eight standard PCIe 4.0 slots",
        "3d": "https://support.xfusion.com/server-3d/res/server/X6000V6/index.html?lang=en"
    },
    "sixth"  : {
        "img":sixth,
        "head":"FusionServer XH321 V6 Server Node",
        "descr":"FusionServer XH321 V6 is a new-generation 1 U half-width two-socket server node. It is innovatively designed to overcome power constraints and improve system storage density. It features powerful computing capabilities, ultra-high storage density, and easy management and maintenance.",
        "feature1":"Excellent Computing Performance",
        "feature2":"Adaptation and Flexible Deployment",
        "feature3":"Extreme Heat Dissipation",
        "model":"FusionServer XH321 V6",
        "formfactor":"1U half-width 2-socket server node",
        "processors":"1 / 2 3rd Generation Intel® Xeon® scalable processor 6300 / 8300 Series, up to 270W",
        "memoryslots":"16 DDR4 DIMM slots, up to 3200 MT/s, up to 2 TB memory capacity (configured with 128 GB memory)",
        "localstorage":"Supports a maximum of six 2.5-inch SAS/SATA/SSD/NVMe disks, and NVMe 2+4 equalization. Supports up to two M.2 2280 or 2242 SATA SSDs Hybrid configuration of different hard disk types",
        "pcie":"2 standard PCIe 4.0x16 half-height half-length expansion slots",
        "ocp":"1 OCP expansion slot",
        "management":"· The BMC chip integrates one dedicated management GE network port to provide comprehensive management features such as fault diagnosis, automatic O&M, and hardware security hardening. · The BMC supports standard interfaces such as Redfish, SNMP, and IPMI2.0. Provides a remote management interface based on HTML5/VNC KVM. CD-free deployment and agentless features simplify management. · Aggregate four compute nodes to any node management interface, simplifying management. · FusionDirector management software is optional. It provides advanced management features, such as stateless computing, batch OS deployment, and automatic firmware upgrade, implementing intelligent and automatic management throughout the lifecycle.",
        "os":"Supports Microsoft Windows Server, Red Hat Enterprise Linux, SUSE Linux Enterprise Server, CentOS, Citrix XenServer, and VMware ESXi.",
        "security":"Security features such as power-on password, administrator password, TPM 2.0, security panel, secure boot, and cover opening detection",
        "temperature":"5ºC - 35ºC",
        "certifications":"CE、UL、FCC、CCC、RoHS etc.",
        "dimensions":"40.7mm x 218.7mm x 632mm",
        "3d": "https://support.xfusion.com/server-3d/res/server/xh321v6/index.html?lang=en"
    },
    "seventh"  : {
        "img":seventh,
        "head":"FusionServer XH321C V6 Liquid-cooled Node",
        "descr":"FusionServer XH321C V6 is our latest-generation 1-U half-width 2-socket liquid-cooled node. It supports liquid cooling for CPUs and DIMMs and 45℃ warm water heat dissipation. Full liquid cooling solution, heat dissipation ratio up to 100%, PUE ≤ 1.05; The board-level liquid cooling solution provides a heat dissipation ratio of up to 80% and a PUE of less than or equal to 1.1.",
        "feature1":"High Energy Efficiency",
        "feature2":"High Reliability",
        "feature3":"Cabinet-level Deployment Solution",
        "model":"FusionServer XH321C V6",
        "formfactor":"1U half-width dual-socket liquid-cooled server node",
        "processors":"1 / 2 3rd Generation Intel® Xeon® scalable processor 6300 / 8300 Series, up to 270W",
        "memoryslots":"16 DDR4 DIMM slots, up to 3200 MT/s, up to 2 TB memory capacity (configured with 128 GB memory)",
        "localstorage":"Supports a maximum of six 2.5-inch SAS/SATA/SSD/NVMe disks, and NVMe 2+4 equalization.Supports up to two M.2 2280 or 2242 SATA SSDs. Hybrid configuration of different hard disk types.",
        "pcie":"1 standard half-height half-length PCIe x16 slot",
        "ocp":"1 OCP expansion slot",
        "management":"· The BMC chip integrates one dedicated management GE network port to provide comprehensive management features such as fault diagnosis, automatic O&M, and hardware security hardening.· The BMC supports standard interfaces such as Redfish, SNMP, and IPMI2.0. Provides a remote management interface based on HTML5/VNC KVM. CD-free deployment and agentless features simplify management. · Aggregate four compute nodes to any node management interface, simplifying management. · FusionDirector management software is optional. It provides advanced management features, such as stateless computing, batch OS deployment, and automatic firmware upgrade, implementing intelligent and automatic management throughout the lifecycle.",
        "os":"Supports Microsoft Windows Server, Red Hat Enterprise Linux, SUSE Linux Enterprise Server, CentOS, Citrix XenServer, and VMware ESXi.",
        "security":"Security features such as power-on password, administrator password, TPM 2.0, security panel, secure boot, and cover opening detection",
        "temperature":"5ºC - 35ºC",
        "certifications":"CE、UL、FCC、CCC、RoHS etc.",
        "dimensions":"40.7 mm x 218.7 mm x 632 mm (excluding the liquid-cooled water inlet and outlet pipes)",
    },
    "eight"  : {
        "img":eight,
        "head":"G5500 Heterogeneous Server Chassis",
        "descr":"FusionServer G5500 is a heterogeneous server for data centers. It has high-density heterogeneous computing capabilities and supports GPUDirect RDMA and P2P. It also supports one-click switchover of heterogeneous topologies, providing optimal heterogeneous topology configurations for diverse application loads. Thanks to its fully modular design, users can enjoy long-term evolution of CPUs and heterogeneous components.",
        "feature1":"Superior Heterogeneous Computing Capability",
        "feature2":"Flexible Heterogeneous Topology Configuration",
        "feature3":"Fully Modular Design",
        "product":"FusionServer G5500 Data Center Heterogeneous Server Chassis",
        "nodesupport":"One full - width or two half - width heterogeneous compute nodes",
        "vcc":"4 x 80 PLUS Platinum 2200 W AC hot-swappable PSUs N+N redundancy configuration",
        "fan":"Six hot-swappable intelligent fan modules N+1 redundancy configuration",
        "pcie":"4 PCIe Gen3 x16 half-height half-length NICs (IB/OPA/Ethernet)",
        "management":"Aggregation management network ports",
        "powersupply":"110V/220V AC or 240V HVDC",
        "dimensions":"175mm*447mm*790mm",
        "3d":"https://support.xfusion.com/server-3d/res/server/g5500/index.html?lang=en"
    },
    "ninth"  : {
        "img":ninth,
        "head":"KunLun 9008 V5 Mission Critical Servers",
        "descr":"Adopting RAS 2.0 technologies and iBMC management processors, KunLun 9008 V5 provides up to 18 TB capacity per node and is compatible with mainstream operating systems, virtualization software, and databases. It provides industry-leading performance in the benchmark performance test for in-memory database scenarios. KunLun 9008 V5 supports physical partitioning to improve resource utilization and simplify O&M. It is ideal for enterprise service scenarios such as core databases, application consolidation for digital transformation, in-memory computing, and HPC fat nodes.",
        "feature1":"Superior Reliability",
        "feature2":"Flexible Consolidation",
        "feature3":"Open Ecosystem",
        "parameters":"9008 V5",
        "formfactor":"8-socket server",
        "memory":"96 DDR4 DIMM slots and up to 18 TB memory capacity",
        "internalstorage":"Up to 48 x 2.5-inch SAS/SATA HDDs or 40 x 2.5-inch NVMe SSDs; supports external storage of M.2 drives (supporting hardware RAID)",
        "raid":"RAID 0, 1, 10, 5, 50, 6, or 60; optional supercapacitor for power-off protection for the cache; RAID state transition; RAID configuration memory; self - diagnosis; web-based remote configuration.",
        "lom":"2 x 10 GE + 2 x GE ports",
        "pcie":"Up to 18 PCIe 3.0 slots",
        "partitioning":"Physical partitioning K-Par: 2 physical partitions are supported",
        "management":"Provides comprehensive management features such as fault diagnosis, automated O&M, and hardware security hardening; supports mainstream standard interfaces such as SNMP and IPMI 2.0, facilitating integration with third-party management software; provides remote management interfaces based on HTML5 and VNC KVM; supports features such as Agentless to simplify management. Optionally configured with FusionDirector management software to provide advanced management features such as automated firmware upgrade, enabling smart and automated entire-lifecycle management.",
        "fan":"8 hot-swappable counter-rotating fan modules in N+1 redundancy mode",
        "dimensions":"(H x W x D): 2,000 mm x 600 mm x 1,550 mm SCE (H x W x D): 325.4 mm x 447 mm x 840 mm",
        "temperature":"5°C to 40°C (41°F to 104°F) (ASHRAE Class A3 compliant)",
        "os":"Red Hat Enterprise Linux (RHEL), SUSE Linux Enterprise Server (SLES), Microsoft Windows Server, VMware ESX For details",
        "3d":"https://support.xfusion.com/server-3d/res/server/9008v5/index.html?lang=en"
    },
    "tenth"  : {
        "img":tenth,
        "head":"FusionDirector Centralized Infrastructure Management Platform",
        "descr":"Purpose-designed to manage servers throughout their entire lifecycles, FusionDirector provides five intelligent management functions covering intelligent maintenance, upgrades, discovery, energy saving, and deployment, improving O&M efficiency by 30%. Its real-time visual interface provides customers with ultimate experience.",
        "feature1":"Intelligent Maintenance",
        "feature2":"Intelligent Upgrade",
        "feature3":"Intelligent Energy Consumption",
        "feature1desc":"Stable Operations, Precise Fault Location, Integrated Pre-Diagnosis, and Self-Healing Functions",
        "feature2desc":"Cloud-Device Collaboration for One-Click Automatic Server Upgrade",
        "feature3desc":"Higher Efficiency Through Dynamic Energy-Saving and Refined Optimization",
    },
    "eleventh"  : {
        "img":eleventh,
        "head":"iBMC Server Management Software",
        "descr":"Intelligent Baseboard Management Controller (iBMC) is an embedded server management system used to manage servers throughout their lifecycles. It provides a series of management tools for hardware status monitoring, deployment, energy savings, and security, and offers standard interfaces to build a complete server management ecosystem.",
        "feature1":"Monitoring and Troubleshooting",
        "feature2":"DEMT",
        "feature3":"Remote O&M",
        "feature1desc":"Fault diagnosis and management (FDM) is a series of diagnosis technologies and tools provided by iBMC. FDM monitors various server components to implement in-depth fault diagnosis and prediction for key server components. It provides a fault locating success rate of 93%.",
        "feature2desc":"The Dynamic Energy Management Technology (DEMT) dynamically and intelligently adjusts the power consumption of each component based on a set of input parameters, such as the CPU load and ambient temperature. The DEMT and hibernation technologies make device energy management more efficient, reducing power consumption by 15% to 30% on average.",
        "feature3desc":"iBMC provides various remote O&M tools and capabilities, including KVM, SOL, virtual media, and web access. It supports remote configuration, upgrade, and deployment, allowing O&M personnel to conveniently access servers and perform configuration and recovery.",
    },
    "twelvth"  : {
        "img":twelvth,
        "head":"FusionServer Tools Toolkit",
        "descr":"FusionServer Tools provides a package of tools for quick in-band and out-of-band server Operation and Maintenance (O&M). It helps customers quickly and conveniently configure servers, diagnose faults, perform routine inspection, and deploy systems in single-node deployment scenarios or without upper-layer management software.",
        "feature1":"One-Click Deployment",
        "feature2":"Proactive Protection",
        "feature3":"Rapid Troubleshooting",
        "feature1desc":"FusionServer Tools provide one-click deployment, including OS installation and configuration of IP addresses and hardware. The deployment wizard helps you easily deploy servers in batches for medium- and large-sized data centers.",
        "feature2desc":"FusionServer Tools FusionServer Tools help you dynamically check server health, including in-band and out-of-band hardware status, logs, and hardware and software configuration. With FusionServer Tools, you can identify risks in advance to ensure smooth service running.",
        "feature3desc":"With FusionServer Tools, you can quickly troubleshoot and rectify typical issues onsite. Spare parts can be allocated to you in no time for service restoration.",
    },
    "thirteenth"  : {
        "img":thirteenth,
        "head":"Management Ecosystem",
        "descr":"Management software provides standard interfaces and provides plug-ins for mainstream management software. It can be easily integrated to build a diversified management ecosystem.",
        "feature1":"VMware",
        "feature2":"Open Source",
        "feature3":"Microsoft Services",
        "feature1desc":"VMware vCenter Plugin, VMware vRealize Operations Manager Plugin, VMware vRealize Log Insight Plugin",
        "feature2desc":"Ansible Plugin, Zenoss Plugin, Cacti Plugin, Nagios Plugin, Metal3+iRonic Plugin, CheckMK Plugin, OpenNMS Plugin, Centreon Plugin, Puppet Plugin, Chef Plugin, Zabbix Plugin",
        "feature3desc":"Microsoft Powershell Cmdlets Plugin, Microsoft System Center Operations Manager Plugin, Microsoft System Center Configuration Manager Plugin",
    }   
}
export default info