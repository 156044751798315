import React, {useEffect} from "react"
import ReactDOM from "react-dom"
import {connect} from "react-redux"
import "./ProductPage.css"

function ProductPage (props) {
    const {info} = props
    useEffect(() => {
        const wrap = document.querySelector(".iframeWrapper")
        function renderFrame () {
            ReactDOM.render (
                <>
                    <h1>3D View</h1>
                    <iframe src={info["3d"]} title="3dview" frameborder="0"></iframe>
                </>,wrap
            )
        }
        if(info["3d"] !== undefined) {renderFrame()}
        else {ReactDOM.render(<></>, wrap)}
    })
    
    return (
        <div id="productWrap">
            <section id="upperProduct">
                <img id="productBanner" src={info.img} alt="" srcset=""/>
                <div className="productDescSection">
                    <h1>{info.head}</h1>
                    <div className="productDesc">{info.descr}</div>
                </div>
            </section>
            <section id="specs">
                <h1>Technical Specifications</h1>
                <table>
                    <tbody>
                        <tr className="topTable">
                            <td>Specification Name</td>
                            <td>Specifications</td>
                        </tr>
                        <tr>
                            <td>Model</td>
                            <td>{info.model}</td>
                        </tr>
                        <tr>
                            <td>Form Factor</td>
                            <td>{info.formfactor}</td>
                        </tr>
                        <tr>
                            <td>Processors</td>
                            <td>{info.processors}</td>
                        </tr>
                        <tr>
                            <td>Chipset</td>
                            <td>{info.chipset}</td>
                        </tr>
                        <tr>
                            <td>Memory</td>
                            <td>{info.memory}</td>
                        </tr>
                        <tr>
                            <td>Local Storage</td>
                            <td>{info.localstorage}</td>
                        </tr>
                        <tr>
                            <td>RAID Support</td>
                            <td>{info.raidsupport}</td>
                        </tr>
                        <tr>
                            <td>Network</td>
                            <td>{info.network}</td>
                        </tr>
                        <tr>
                            <td>PCIe Expansion</td>
                            <td>{info.pcieexpansion}</td>
                        </tr>
                        <tr>
                            <td>Fan Modules</td>
                            <td>{info.fanmodules}</td>
                        </tr>
                        <tr>
                            <td>Power Supply</td>
                            <td>{info.powersupply}</td>
                        </tr>
                        <tr>
                            <td>Management</td>
                            <td>{info.management}</td>
                        </tr>
                        <tr>
                            <td>Operating Systems</td>
                            <td>{info.operatingsystems}</td>
                        </tr>
                        <tr>
                            <td>Security Features</td>
                            <td>{info.securityfeatures}</td>
                        </tr>
                        <tr>
                            <td>Operating Temperature</td>
                            <td>{info.operatingtemperature}</td>
                        </tr>
                        <tr>
                            <td>Certifications</td>
                            <td>{info.certifications}</td>
                        </tr>
                        <tr>
                            <td>Installation Kit</td>
                            <td>{info.installationkit}</td>
                        </tr>
                        <tr>
                            <td>Dimensions (H x W x D)</td>
                            <td>{info.dimensions}</td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section className="iframeWrapper productIframe">

            </section>
            <button onClick={props.specs} id="learnMore">Documentations</button>
        </div>  
        
    )
}
const mapState = (state) => {
    return ({
        state:state
    })
}

const mapAct = (deploy) => {
    return (
        {
            specs: () => deploy({type:"specs"}),
        }
    )
}
export default connect(mapState, mapAct)(ProductPage)