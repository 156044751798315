import React, {useState} from 'react';
import './Header.css';
import {connect} from "react-redux"
import logo from "../../src/media/biglogo.png" 
import logo2 from "../../src/media/fusLogo.svg"
import cross from "../../src/media/cross.png"

//headers
import Products from './headBottoms/Products';
import Technical from "./headBottoms/Technical"

function Header(props) {
    let [currentHeader, setHeader] = useState(<></>)
    function closeHeader (event) {
        const cross = document.getElementById("crossIcon")
        const header = document.querySelector("header")
        if(cross.className === "") return
        setHeader(<></>)
        header.className = ""
        cross.className = ""
    }
    function expandHeader (e) {
        const header = document.querySelector("header")
        const cross = document.getElementById("crossIcon")
        if(header.className !== "expanded") {
            header.className = "expanded"
            cross.className = "cross"
        }
        switch(e.target.id) {
            default:
                console.log("error")
                break
            case "products":
                setHeader(<Products func={closeHeader}/>)
                break
            case "technical":
                setHeader(<Technical func={closeHeader}/>)
                break
        }
    }
    
    return (
            <header>
                <div id="headerShell">
                        <div id="logosWrapper">
                            <img onClick={props.main} id="headLogo" src={logo} alt="" />
                            <a><img onClick={props.main} id="headLogo2" src={logo2} alt="" /></a>
                        </div>
                        <div id="headLinks">
                            <div id="products" onClick={expandHeader} className='headLink'>Products and Solutions</div>
                            <div id="technical" onClick={expandHeader} className='headLink'>Technical Support</div>
                            <div onClick={props.about} className='headLink'>About Absolut xFusion</div>
                            <div onClick={props.partner} className='headLink'>Become a partner</div>
                            <img onClick={closeHeader} id="crossIcon" className='' src={cross} alt=""/>
                        </div>
                </div>
                <div id="downHeader">
                    {currentHeader}
                </div>
            </header>
    );
}

const mapState = (state) => {
    return ({
        state:state
    })
}

const mapAct = (deploy) => {
    return (
        {
            main: () => deploy({type:"main"}),
            about: () => deploy({type:"about"}),
            partner: () => deploy({type:"partner"}),
        }
    )
}

export default connect(mapState,mapAct)(Header);