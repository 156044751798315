import React from "react"
import {connect} from "react-redux"
import banner from "../../media/products.jpg"
import "./Specs.css"



function Specs (props) {
    return (
        <>
            <section id="specs">
                <img className="banners" src={banner} alt="" />
                <h1>Product Specification Documentation</h1>
                <div id="docWrapper">
                    <div className="secDoc">
                        <h3>
                            Rack Servers
                        </h3>
                        <span><a href="./FusionServer-1288H-V6-Server-Technical-White-Paper-01.pdf" download>1288H V6</a></span>
                        <span><a href="./FusionServer-2288H-V6-Server-Technical-White-Paper.pdf" download>2288H V6</a></span>
                        <span><a href="./FusionServer-2488H-V6-Server-Technical-White-Paper.pdf" download>2488H V6</a></span>
                        <span><a href="./FusionServer-5288-V6-Server-Technical-White-Paper-01.pdf" download>5288 V6</a></span>
                    </div>
                    <div className="secDoc">
                        <h3>
                            High-Density Servers
                        </h3>
                        <span><a href="./FusionServer  X6000 V6 High-Density Server Datasheet.pdf" download>X6000</a></span>
                    </div>
                    <div className="secDoc">
                        <h3>
                            Mission Critical Servers
                        </h3>
                        <span><a href="./KunLun-9008-V5-Mission-Critical-Server-Technical-White-Paper.pdf" download>9008 V5</a></span>
                    </div>
                    <div className="secDoc">
                        <h3>
                            Heterogeneous Servers
                        </h3>
                        <span><a href="./FusionServer-G5500-Server-Technical-White-Paper.pdf" download>FusionServer G5500</a></span>
                    </div>
                    <div className="secDoc">
                        <h3>
                            Server Management Software
                        </h3>
                        <span><a href="./iBMC-Intelligent-Management-System-White-Paper.pdf" download>iBMA</a></span>
                        <span><a href="./FusionDirector-Management-Software-Technical-White-Paper-01.pdf" download>FusionDirector</a></span>
                    </div>
                </div>
            </section>
        </>  
        
    )
}
export default connect()(Specs)