import React, {useEffect} from "react"
import ReactDOM from "react-dom"
import {connect} from "react-redux"
import star from "../../media/features.png"

function GPUserv (props) {
    const {info} = props
    useEffect(() => {
        const wrap = document.querySelector(".iframeWrapper")
        function renderFrame () {
            ReactDOM.render (
                <>
                    <h1>3D View</h1>
                    <iframe src={info["3d"]} title="3dview" frameborder="0"></iframe>
                </>,wrap
            )
        }
        if(info["3d"] !== undefined) {renderFrame()}
        else {ReactDOM.render(<></>, wrap)}
    })
    return (
        <div id="productWrap">
            <section id="upperProduct">
                <img id="productBanner" src={info.img} alt="" srcset=""/>
                <div className="productDescSection">
                    <h1>{info.head}</h1>
                    <div className="productDesc">{info.descr}</div>
                </div>
            </section>
            <section id="specs">
                <h1>Technical Specifications</h1>
                <table>
                    <tbody>
                        <tr className="topTable">
                            <td>Specification Name</td>
                            <td>Specifications</td>
                        </tr>
                        <tr>
                            <td>Product</td>
                            <td>{info.product}</td>
                        </tr>
                        <tr>
                            <td>Node Support</td>
                            <td>{info.nodesupport}</td>
                        </tr>
                        <tr>
                            <td>VCC</td>
                            <td>{info.vcc}</td>
                        </tr>
                        <tr>
                            <td>Fan</td>
                            <td>{info.fan}</td>
                        </tr>
                        <tr>
                            <td>PCIe</td>
                            <td>{info.pcie}</td>
                        </tr>
                        <tr>
                            <td>Management</td>
                            <td>{info.management}</td>
                        </tr>
                        <tr>
                            <td>Power supply</td>
                            <td>{info.powersupply}</td>
                        </tr>
                        <tr>
                            <td>Dimensions(H x W x D)</td>
                            <td>{info.dimensions}</td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section className="iframeWrapper">

            </section>
            <button onClick={props.specs} id="learnMore">Documentations</button>
        </div>  
        
    )
}
const mapState = (state) => {
    return ({
        state:state
    })
}

const mapAct = (deploy) => {
    return (
        {
            specs: () => deploy({type:"specs"}),
        }
    )
}
export default connect(mapState, mapAct)(GPUserv)